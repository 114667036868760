.page-users {
  .MuiTable-root {
    .row-user {
      .MuiTableCell-root {
        height: 3.5rem;
      }
    }
  }
}

.page-user-detail {
  // text field
  .MuiTextField-root {
    // label
    .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
      transform: translate(1rem, 0.7rem) scale(1);
      font-size: 0.8rem;
      line-height: 1rem;
      font-weight: bold;
    }

    // input
    input {
      padding: 0.7rem 1rem 0.7rem 1rem !important;
      font-size: 0.8rem;
    }
  }

  .MuiOutlinedInput-root {
    select {
      padding: 0.7rem 1rem 0.7rem 1rem !important;
      font-size: 0.8rem;
    }
  }

  .MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      font-size: 0.8rem;
    }
  }
}
