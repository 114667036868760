.page-posts {
}

.page-post-detail {
  .avatar-img {
    @apply w-full;
    max-width: 24rem;
    min-height: 16rem;
  }

  // text field
  .MuiTextField-root {
    // input
    input {
      @apply text-sm;
    }
  }
}
