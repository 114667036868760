.page-policy {
  .dv-content {
    box-shadow: rgba(100, 100, 111, 0.2) 0 0 19px 0;

    p {
      @apply mb-3;
    }
    ul {
      @apply list-disc;
      @apply ml-4;
    }

    // text field
    .MuiTextField-root {
      // label
      .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
        transform: translate(1rem, 0.7rem) scale(1);
        font-size: 0.8rem;
        line-height: 1rem;
      }

      // input
      input {
        padding: 0.7rem 1rem 0.7rem 1rem !important;
        font-size: 0.8rem;
      }
    }
  }
}
