.menu-sort {
  .MuiList-root {
    @apply py-1;
  }

  .MuiListItem-root {
    @apply py-0;
    @apply pl-2;
  }

  .MuiListItemIcon-root {
    min-width: 1.8rem;
  }

  .MuiListItemText-primary {
    @apply text-sm;
  }
}
