@import "src/styles/theme";

.dv-header {
  .txt-search {
    border-radius: 4rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0 0 19px 0;

    .MuiOutlinedInput-root {
      border-radius: 4rem;

      .MuiOutlinedInput-notchedOutline {
        border-width: 0;
      }
    }

    // input
    input {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
    }
  }
}
