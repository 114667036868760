/********************************
 *
 * THEME CSS VARIABLES
 *
 *******************************/

$color-primary: #164E7A;
$color-primary-light: #8BC0EA;
$color-primary-light-bg: #8BC0EA17;
$color-gray-light: #BEBEBE;
$color-gray: #707070;
