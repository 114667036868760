@import "~react-image-lightbox/style.css";

.main-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.but-light {
  &.MuiButton-containedPrimary, &.MuiButton-containedPrimary:hover {
    background-color: $color-primary-light;
  }

  &.MuiButton-text, &.MuiButton-text:hover {
    background-color: $color-primary-light;
  }
}

.txt-primary {
  color: $color-primary !important;
}
.txt-primary-light {
  color: $color-primary-light !important;
}

.bg-light-transparent {
  background-color: $color-primary-light-bg;
}

.dv-txt-oneline {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

//
// table
//
.dv-table-main {
  min-height: 12rem;
}

.pgn-main.MuiPagination-root {
  .MuiPaginationItem-root {
    @apply outline-none;
    @apply text-gray-500;

    &.Mui-selected {
      background-color: $color-primary-light;
      color: white;
    }
  }
}

//
// popper
//
.popper-tags {
  @apply text-sm;
}
.MuiAutocomplete-listbox {
  @apply text-sm;
  padding: 0.2rem 0 !important;
}
.MuiAutocomplete-noOptions {
  @apply text-sm;
}
