@import "src/styles/theme";

.page-signup {
  .dv-form {
    border-radius: 14px;
    width: 420px;

    box-shadow: rgba(100, 100, 111, 0.2) 0 0 29px 0;
  }

  // text field
  .MuiTextField-root {
    .MuiOutlinedInput-root {
      border-radius: 4rem;
    }

    // label
    .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
      transform: translate(1rem, 0.7rem) scale(1);
      font-size: 0.8rem;
      line-height: 1rem;
      font-weight: bold;
    }

    // input
    input {
      padding: 0.7rem 1rem 0.7rem 1rem !important;
      font-size: 0.8rem;
    }
  }

  // autocomplete
  .MuiAutocomplete-inputRoot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  // checkboxes
  .check-agree.MuiFormControlLabel-root {
    display: flex;
    align-items: flex-start;

    .MuiFormControlLabel-label {
      font-size: 0.74rem;
      margin-top: 0.4rem;
      color: $color-gray;
      line-height: 0.9rem;
    }
  }
}
